import React from 'react';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';

import './../../../assets/scss/style.scss';
import Aux from "../../../hoc/_Aux";
import logoDark from '../../../assets/images/EmergeRemit.png';

import { Alert } from "reactstrap";
import { Button } from 'react-bootstrap';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { connect } from "react-redux";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import LoadingOverlay from 'react-loading-overlay';

import {
    setUserLoading,
    CreateUser,
    dismissUserError
} from "../../../store/actions/ewallet/users";

const initStates = {
    isLoading: false,
    email: "",
    password: "",
    confirmpassword: "",
    phoneNumber: "",
    firstname: "",
    middleName: "",
    lastname: "",
    gender: "Male"
};

class SignUp1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = initStates
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    handleSubmit = (e, formData, inputs) => {

        e.preventDefault();

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Confirm your action</h1>
                        <p>Are you sure you want to register this account?</p>
                        <Button onClick={onClose} className='btn-icon m-r-5' variant={'outline-secondary'}>Cancel</Button>
                        <Button
                            onClick={() => {

                                let params = queryString.parse(this.props.location.search)
                                if (params && params.ref) formData.referredByToken = params.ref;

                                this.props.setUserLoading("Processing Request...");
                                this.props.createUser(formData);
                                this.setState(initStates);
                                onClose();
                            }}
                            className='btn-icon' variant={'outline-info'}>Confirm</Button>
                    </div>
                );
            }
        });
    }

    render() {
        return (
            <Aux>
                <header className="header-section">
                    <div className="header">
                        <div className="header-bottom-area">
                            <div className="container">
                                <div className="header-menu-content">
                                    <nav className="navbar navbar-expand-lg p-0">
                                        <a className="site-logo site-title" href="https://emergeremit.com"><img src={logoDark}
                                            alt="site-logo" /></a>
                                        <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ float: "right", paddingRight: 450 }}>
                                            <ul className="navbar-nav main-menu mx-auto">
                                                <li><a href="https://emergeremit.com">Home</a></li>
                                                <li><a href="https://emergeremit.com/how-it-works.html">How it works</a></li>
                                                <li><a href="https://agent.emergeremit.com/" className="active">Agent</a></li>
                                                <li><a href="https://emergeremit.com/contact.html">Contact Us</a></li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <LoadingOverlay
                    active={this.props.loading}
                    spinner
                    text={"Account creation in progress..."}
                >
                    <div className="page-ath-wrap">
                        <div className="page-ath-content">

                            <div className="page-ath-form">
                                <h2 className="page-ath-heading">Sign Up <small>to have your EmergeRemit wallet account</small></h2>
                                <Alert color={'danger'}
                                    isOpen={this.props.error}
                                    toggle={this.props.dismissError}>
                                    {this.props.errorMsg}
                                </Alert>
                                <Alert color={'success'}
                                    isOpen={this.props.usertCreated}
                                    toggle={this.props.dismissError}>
                                    Account has successfully been created. A KYC process will be on progress.
                                </Alert>
                                <ValidationForm autoComplete={false} onSubmit={this.handleSubmit} onErrorSubmit={this.handleErrorSubmit}>
                                    <div className="input-item">
                                        <TextInput
                                            name="email"
                                            id="email"
                                            placeholder="Your Email"
                                            type="email"
                                            required
                                            errorMessage={{ required: "Username is required" }}
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                            className="input-bordered"
                                        />
                                    </div>
                                    <div className="input-item">
                                        <TextInput
                                            name="phoneNumber"
                                            id="phoneNumber"
                                            placeholder="Your Phone Number"
                                            type="text"
                                            required
                                            errorMessage={{ required: "Phone number is required" }}
                                            value={this.state.phoneNumber}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                            className="input-bordered"
                                        />
                                    </div>
                                    <div className="input-item">
                                        <TextInput
                                            name="firstname"
                                            id="firstname"
                                            type="text"
                                            placeholder="First Name"
                                            required
                                            errorMessage={{ required: "First name is required" }}
                                            value={this.state.firstname}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                            className="input-bordered"
                                        />
                                    </div>
                                    <div className="input-item">
                                        <TextInput
                                            name="middleName"
                                            id="middleName"
                                            type="text"
                                            placeholder="Middle Name"
                                            value={this.state.middleName}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                            className="input-bordered"
                                        />
                                    </div>
                                    <div className="input-item">
                                        <TextInput
                                            name="lastname"
                                            id="lastname"
                                            type="text"
                                            placeholder="Last Name"
                                            required
                                            errorMessage={{ required: "Last name is required" }}
                                            value={this.state.lastname}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                            className="input-bordered"
                                        />
                                    </div>
                                    <div className="input-item">
                                        <TextInput
                                            name="password"
                                            id="password"
                                            type="password"
                                            placeholder="Password"
                                            required
                                            errorMessage={{ required: "Password is required" }}
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            autoComplete="off"
                                            className="input-bordered"
                                        />
                                    </div>
                                    {
                                        this.props.loading ?
                                            <Button className="btn btn-primary btn-block" disabled>
                                                <span className="spinner-border spinner-border-sm mr-1" role="status" />Loading...
                                            </Button> :
                                            <Button type="submit" className="btn btn-primary btn-block">Register</Button>
                                    }
                                </ValidationForm>
                                <div className="gaps-2x"></div>
                                <div className="gaps-2x"></div>
                                <div className="form-note">Already have an account? <NavLink to="/auth/signin" className="f-w-400"><strong>Sign in here</strong></NavLink> </div>
                            </div>

                            <div className="page-ath-footer">
                                <ul className="footer-links">
                                    <li><a href="https://emergeremit.com/privacy.html">Privacy Policy</a></li>
                                    <li><a href="https://emergeremit.com/privacy.html">Terms</a></li>
                                    <li>&copy; 2020 EmergeRemit.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="page-ath-gfx">
                            <div className="w-100 d-flex justify-content-center">
                                {/* <div className="col-md-8 col-xl-5"><img src={athGfx} alt="image" /></div> */}
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        ...state.users,
        auth: state.auth
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUserLoading: () => {
            dispatch(setUserLoading())
        },
        createUser: objValues => {
            dispatch(CreateUser(objValues));
        },
        dismissError: () => {
            dispatch(dismissUserError());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp1);